import Tooltip from "./TooltipBox";

interface DropdownProps<T> {
	label: string;
	value: T;
	onChange: (value: T) => void;
	options: T[];
	tooltipText: string;
}

const Dropdown = <T,>({
	label,
	value,
	onChange,
	options,
	tooltipText,
}: DropdownProps<T>) => {
	return (
		<div>
			<div className='flex items-center'>
				<label className='block text-sm font-medium text-gray-100'>
					{label}
				</label>
				<Tooltip text={tooltipText} />
			</div>
			<select
				value={value as string}
				onChange={(e) => onChange(e.target.value as T)}
				className='mt-1 block w-full px-3 py-2 border border-gray-600 bg-gray-700 text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'>
				{options.map((opt, index) => (
					<option key={index} value={opt as string}>
						{opt as string}
					</option>
				))}
			</select>
		</div>
	);
};

export default Dropdown;
