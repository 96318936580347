import { useAppContext } from "../AppContext";
import Loading from "./ui/Loading";
import { FileSizeComparison } from "./ui/SizeComparison";

export const VideoPlayer = () => {
	const { id, url, fileSize } = useAppContext();

	if (!url) {
		return (
			<div className='flex items-center justify-center h-full mt-36'>
				<Loading />
			</div>
		);
	}

	return (
		<div className='max-w-xl mx-auto mt-10 p-6 bg-gray-800 rounded-lg shadow-md'>
			<h2 className='text-2xl text-center font-semibold mb-4 text-gray-100'>
				Video
			</h2>
			<div className='aspect-w-16 aspect-h-9'>
				<video controls className='w-full h-full rounded-lg'>
					<source src={url as string} type='video/mp4' />
					Your browser does not support the video tag.
				</video>
			</div>
			<div className='flex justify-center mt-4'>
				<a
					href={url as string}
					download={`${id}.mp4`}
					className='bg-purple-700 text-white px-4 py-2 rounded-md hover:bg-purple-800 transition duration-300'>
					Download Video
				</a>
			</div>
			<div className='mt-4'>
				<FileSizeComparison
					beforeSize={fileSize?.before as number}
					afterSize={fileSize?.after as number}
				/>
			</div>
		</div>
	);
};
