import {
	createContext,
	useState,
	useContext,
	ReactNode,
	useEffect,
} from "react";
import { FFMpegOptions } from "./components/VideoOptions";
import { GetUploadUrlAndId, UploadFile } from "./api";

interface IContext {
	step: number;
	goToNextStep: () => void;
	reset: () => void;
	file: File | null;
	setFile: (file: File) => void;
	options: FFMpegOptions | null;
	setOptionsAndStartUpload: (options: FFMpegOptions) => void;
	id: string | null;
	setId: (id: string) => void;
	url: string | null;
	setUrl: (url: string) => void;
	fileSize: FileSize | null;
	setFileSize: (fileSize: FileSize) => void;
}

interface IAppProvider {
	children: ReactNode;
}

export interface FileSize {
	before: number;
	after: number;
}

const AppContext = createContext<IContext | null>(null);

export const AppProvider = ({ children }: IAppProvider) => {
	const [step, setStep] = useState(0);
	const [file, setFile] = useState<File | null>(null);
	const [options, setOptions] = useState<FFMpegOptions | null>(null);
	const [id, setId] = useState<string | null>(null);
	const [url, setUrl] = useState<string | null>(null);
	const [fileSize, setFileSize] = useState<FileSize | null>(null);

	const goToNextStep = () => {
		setStep((prevStep) => prevStep + 1);
	};

	const reset = () => {
		setStep(0);
		setFile(null);
		setOptions(null);
		setId(null);
	};

	useEffect(() => {
		const startUpload = async () => {
			const results = await GetUploadUrlAndId(options as FFMpegOptions);
			if (results) {
				const { uploadUrl, id } = results;
				setId(id);
				await UploadFile(uploadUrl, file as File);
				return true;
			}
			return false;
		};

		if (options && file) {
			startUpload();
		}
	}, [options, file]);

	const values = {
		step,
		goToNextStep,
		reset,
		file,
		setFile,
		options,
		setOptionsAndStartUpload: setOptions,
		id,
		setId,
		url,
		setUrl,
		fileSize,
		setFileSize,
	};

	return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};

export const useAppContext = (): IContext => {
	const context = useContext(AppContext);
	if (!context) {
		throw new Error("useAppContext must be used within a AppProvider");
	}
	return context;
};
