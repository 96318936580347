import React, { useState } from 'react';
import { useAppContext } from '../AppContext';

export const FileSelector: React.FC = () => {
    const { setFile, goToNextStep } = useAppContext();
    const [error, setError] = useState('');

    const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (!file) {
            setError('No file selected');
            return;
        }

        if (file.type !== 'video/mp4') {
            setError('Only MP4 files are allowed');
            return;
        }

        setError('');
        setFile(file);
        goToNextStep();
    };

    return (
        <div className='flex items-center justify-center h-full mt-36'>
            <div className='bg-gray-800 p-6 rounded-lg shadow-lg text-center'>
                <input
                    type='file'
                    accept='video/mp4'
                    className='block w-full text-sm text-gray-300
                        file:mr-4 file:py-2 file:px-4
                        file:rounded-full file:border-0
                        file:text-sm file:font-semibold
                        file:bg-gray-700 file:text-gray-300
                        hover:file:bg-gray-600'
                    onChange={handleUpload}
                />
                {error && <div className='text-red-400 mt-2'>{error}</div>}
            </div>
        </div>
    );
};