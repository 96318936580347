interface TooltipProps {
	text: string;
}

const Tooltip: React.FC<TooltipProps> = ({ text }) => {
	return (
		<div className='relative flex items-center'>
			<div className='ml-2 flex items-center'>
				<div className='relative flex flex-col items-center group'>
					<span className='flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-purple-700 rounded-full cursor-pointer'>
						i
					</span>
					<div className='absolute left-1/2 transform -translate-x-1/2 bottom-full mb-2 flex-col items-center hidden group-hover:flex'>
						<span className='relative z-10 p-2 text-xs leading-none text-white bg-gray-700 shadow-lg rounded-md max-w-xs w-48'>
							{text}
						</span>
						<div className='w-3 h-3 rotate-45 bg-gray-700 -mt-2'></div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Tooltip;
