import React, { useEffect, useState } from "react";
import Dropdown from "./ui/Dropdown";
import { useAppContext } from "../AppContext";
import tooltips from "../tooltips.json";
import Loading from "./ui/Loading";

enum VideoCodec {
	libx264 = "libx264",
	libx265 = "libx265",
	libvpx = "libvpx",
	mpeg4 = "mpeg4",
}

enum CrfValue {
	value18 = "18",
	value20 = "20",
	value23 = "23",
	value28 = "28",
}

enum VideoBitrate {
	value500k = "500k",
	value800k = "800k",
	value1M = "1M",
	value2M = "2M",
	value4M = "4M",
}

enum AudioBitrate {
	value96k = "96k",
	value128k = "128k",
	value192k = "192k",
	value256k = "256k",
}

enum Resolution {
	unchanged = "Unchanged",
	value1920x1080 = "1920:1080",
	value1280x720 = "1280:720",
	value854x480 = "854:480",
	value640x360 = "640:360",
	value426x240 = "426:240",
}

enum FrameRate {
	unchanged = "Unchanged",
	value24 = "24",
	value25 = "25",
	value30 = "30",
	value48 = "48",
	value50 = "50",
	value60 = "60",
}

export interface FFMpegOptions {
	videoCodec: VideoCodec;
	crfValue: CrfValue;
	videoBitrate: VideoBitrate;
	audioBitrate: AudioBitrate;
	resolution: Resolution;
	frameRate: FrameRate;
}

export const VideoOptions = () => {
	const { file, id, goToNextStep, setOptionsAndStartUpload } =
		useAppContext();
	const [loading, setLoading] = useState(false);

	const [videoCodec, setVideoCodec] = useState<VideoCodec>(
		VideoCodec.libx264,
	);
	const [crfValue, setCrfValue] = useState<CrfValue>(CrfValue.value23);
	const [videoBitrate, setVideoBitrate] = useState<VideoBitrate>(
		VideoBitrate.value1M,
	);
	const [audioBitrate, setAudioBitrate] = useState<AudioBitrate>(
		AudioBitrate.value128k,
	);
	const [resolution, setResolution] = useState<Resolution>(
		Resolution.unchanged,
	);
	const [frameRate, setFrameRate] = useState<FrameRate>(FrameRate.unchanged);

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		setLoading(true);

		const options: FFMpegOptions = {
			videoCodec,
			crfValue,
			videoBitrate,
			audioBitrate,
			resolution,
			frameRate,
		};
		setOptionsAndStartUpload(options);
	};

	useEffect(() => {
		if (id) {
			setLoading(false);
			goToNextStep();
		}
	}, [id, goToNextStep]);

	if (loading) {
		return (
			<div className='flex items-center justify-center h-full mt-36'>
				<Loading />
			</div>
		);
	}

	return (
		<form
			onSubmit={handleSubmit}
			className='max-w-xl mx-auto mt-10 p-6 bg-gray-800 rounded-lg shadow-md space-y-4'>
			<h2 className='text-2xl font-semibold mb-4 text-gray-100'>
				Optimization Options
			</h2>

			<Dropdown
				label='Video Codec'
				value={videoCodec}
				onChange={setVideoCodec}
				options={Object.values(VideoCodec)}
				tooltipText={tooltips.videoCodec}
			/>

			<Dropdown
				label='CRF Value'
				value={crfValue}
				onChange={setCrfValue}
				options={Object.values(CrfValue)}
				tooltipText={tooltips.crfValue}
			/>

			<Dropdown
				label='Video Bitrate'
				value={videoBitrate}
				onChange={setVideoBitrate}
				options={Object.values(VideoBitrate)}
				tooltipText={tooltips.videoBitrate}
			/>

			<Dropdown
				label='Audio Bitrate'
				value={audioBitrate}
				onChange={setAudioBitrate}
				options={Object.values(AudioBitrate)}
				tooltipText={tooltips.audioBitrate}
			/>

			<Dropdown
				label='Resolution'
				value={resolution}
				onChange={setResolution}
				options={Object.values(Resolution)}
				tooltipText={tooltips.resolution}
			/>

			<Dropdown
				label='Frame Rate'
				value={frameRate}
				onChange={setFrameRate}
				options={Object.values(FrameRate)}
				tooltipText={tooltips.frameRate}
			/>

			<button
				type='submit'
				disabled={!file}
				className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-100 bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-purple-400 disabled:cursor-not-allowed'>
				Start
			</button>
		</form>
	);
};
