import React from 'react';

// Helper function to convert bytes to human-readable format
const bytesToHumanReadable = (sizeBytes: number): string => {
    if (sizeBytes === 0) return '0B';
    const sizeName = ['B', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(sizeBytes) / Math.log(1024));
    const p = Math.pow(1024, i);
    const s = (sizeBytes / p).toFixed(2);
    return `${s} ${sizeName[i]}`;
};

interface FileSizeComparisonProps {
    beforeSize: number;
    afterSize: number;
}

export const FileSizeComparison = ({ beforeSize, afterSize }: FileSizeComparisonProps) => {
    const humanReadableBeforeSize = bytesToHumanReadable(beforeSize);
    const humanReadableAfterSize = bytesToHumanReadable(afterSize);

    const maxSize = Math.max(beforeSize, afterSize);
    const beforePercentage = ((beforeSize / maxSize) * 100).toFixed(2);
    const afterPercentage = ((afterSize / maxSize) * 100).toFixed(2);

    return (
        <div className="max-w-xl mx-auto p-4">
            <h2 className="text-xl font-semibold mb-4 text-center">File Size Comparison</h2>
            <div className="mb-4">
                <div className="flex justify-between mb-2">
                    <span>Before: {humanReadableBeforeSize}</span>
                    <span>{beforePercentage}%</span>
                </div>
                <div className="w-full bg-gray-200 rounded-full h-6">
                    <div
                        className="bg-blue-600 h-6 rounded-full"
                        style={{ width: `${beforePercentage}%` }}
                    ></div>
                </div>
            </div>
            <div className="mb-4">
                <div className="flex justify-between mb-2">
                    <span>After: {humanReadableAfterSize}</span>
                    <span>{afterPercentage}%</span>
                </div>
                <div className="w-full bg-gray-200 rounded-full h-6">
                    <div
                        className="bg-green-600 h-6 rounded-full"
                        style={{ width: `${afterPercentage}%` }}
                    ></div>
                </div>
            </div>
        </div>
    );
};