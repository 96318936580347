import React from "react";
import { useAppContext } from "../AppContext";
import { gradientTextStyles } from "./VideoStatus";

const Header: React.FC = () => {
	const { reset } = useAppContext();

	return (
		<header
			onClick={reset}
			className='flex flex-col items-center justify-center p-8 bg-gray-800 shadow-md cursor-pointer'>
			<h1 className='text-5xl font-extrabold text-gray-50 tracking-wide' style={gradientTextStyles}>
				Optivid
			</h1>
			<p className='text-sm font-semibold text-gray-300 mt-2'>
				Cloud powered video optimization tool 🚀
			</p>
		</header>
	);
};

export default Header;
