import axios from "axios";
import { FFMpegOptions } from "./components/VideoOptions";
import { FileSize } from "./AppContext";

const UPLOAD_ENDPOINT = "/api/upload-url/";
const STATUS_ENDPOINT = "/websocket/"

export const GetUploadUrlAndId = async (options: FFMpegOptions) => {
	try {
		const response = await axios.post(
			UPLOAD_ENDPOINT,
			{ options },
			{
				headers: {
					"Content-Type": "application/json",
				},
			},
		);
		const { upload_url, object_name } = response.data;
		const id = object_name.split(".")[0];

		return { uploadUrl: upload_url, id };
	} catch (error) {
		console.error(error);
	}
};

export const UploadFile = async (uploadUrl: string, file: File) => {
	try {
		const response = await axios.put(uploadUrl, file, {
			headers: {
				"Content-Type": file.type,
			},
		});

		return response;
	} catch (error) {
		console.error(error);
	}
};

export const GetStatus = (
	id: string,
	setStatus: (status: string) => void,
	setUrl: (url: string) => void,
	goToNextStep: () => void,
	setFileSize: (fileSize: FileSize) => void,
) => {
	const ws = new WebSocket(`${STATUS_ENDPOINT}?id=${id}`);

	ws.onopen = () => {
		console.log("Connected to websocket");
	};

	ws.onmessage = (event) => {
		const message = JSON.parse(event.data);
		if (message.url) {
			ws.close();
			setFileSize({
				before: message.file_size_before,
				after: message.file_size_after,
			});
			setUrl(message.url);
			goToNextStep();
		} else {
			setStatus(message.statusText);
		}
	};

	ws.onclose = () => {
		console.log("Disconnected from websocket");
	};

	return ws;
};
