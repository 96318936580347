import { useAppContext } from "../AppContext";
import Loading from "./ui/Loading";
import { GetStatus } from "../api";
import { useEffect, useState } from "react";

export const gradientTextStyles = {
	background: "linear-gradient(to right, purple, pink)",
	WebkitBackgroundClip: "text",
	WebkitTextFillColor: "transparent",
};

export const VideoStatus = () => {
	const { id, goToNextStep, setUrl, setFileSize } = useAppContext();
	const [status, setStatus] = useState<string | null>(null);

	useEffect(() => {
		const fetchStatus = async () => {
			const ws = await GetStatus(
				id as string,
				setStatus,
				setUrl,
				goToNextStep,
				setFileSize
			);

			window.onbeforeunload = () => {
				ws.close();
			};

			return () => {
				ws.close();
			};
		};

		fetchStatus();
	}, [id, goToNextStep, setUrl]);

	return (
		<div className='max-w-xl mx-auto mt-10 p-6 bg-gray-800 rounded-lg shadow-md space-y-4'>
			<h2 className='text-3xl font-semibold mb-4 text-gray-200 text-center'>
				Status
			</h2>
			{status === null ? (
				<div className='flex items-center justify-center h-full mt-36'>
					<Loading />
				</div>
			) : (
				<div className='text-center'>
					<p
						className='text-2xl text-gray-100 animate-pulse font-semibold'
						style={gradientTextStyles}>
						{status}
					</p>
					<p className='text-gray-400 text-sm mt-2'>
						Please wait while the video is being processed.
					</p>
				</div>
			)}
		</div>
	);
};
