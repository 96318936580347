import React from "react";
import Header from "./components/Header";
import { FileSelector } from "./components/FileSelector";
import { VideoOptions } from "./components/VideoOptions";
import { VideoStatus } from "./components/VideoStatus";
import { useAppContext } from "./AppContext";
import { VideoPlayer } from "./components/VideoPlayer";

const App: React.FC = () => {
	const { step } = useAppContext();

	return (
		<div className='min-h-screen bg-gray-900 text-white'>
			<Header />
			<div
				key={step}
				className='container mx-auto p-4 transition-opacity duration-500 ease-in-out'>
				{step === 0 && <FileSelector />}
				{step === 1 && <VideoOptions />}
				{step === 2 && <VideoStatus />}
				{step === 3 && <VideoPlayer />}
			</div> 
		</div>
	);
};

export default App;
